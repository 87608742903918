<style lang="scss" scoped>
	h1 + img {
		margin: 0 auto;
		margin-bottom: 40px;
	}

	h2 {
		position: relative;
		// font-weight: bold;
		font-size: 18px;
		transition: all 0.5s ease-out;
		background: $grey;
		color: #fff;
		padding: 5px;
		border-radius: 5px;

		&::after {
			position: absolute;
			right: 10px;
			transition: all 0.25s ease-out;
			height: 18px;
			content: url('data:image/svg+xml; utf8, <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20"><rect x="-2" y="-2" width="20" height="20" transform="translate(18 18) rotate(180)" style="fill: none;"></rect><path fill="white" d="M6.33,6.8h11a0,0,0,0,1,0,0V8.67a0,0,0,0,1,0,0h-11a.75.75,0,0,1-.75-.75V7.55a.75.75,0,0,1,.75-.75Z" transform="translate(27.05 7.09) rotate(135)" class="arrow"></path><path fill="white" d="M-1.34,6.83H9.59a.75.75,0,0,1,.75.75V8a.75.75,0,0,1-.75.75H-1.34a0,0,0,0,1,0,0V6.83a0,0,0,0,1,0,0Z" transform="translate(4.2 18.44) rotate(-135)" class="arrow"></path></svg>');
		}

		&.open {
			&::after {
				transform: rotate(180deg);
			}
		}
	}

	ul {
		li {
			cursor: pointer;
			overflow: hidden;

			// &:hover {
			// 	h2 {
			// 		text-decoration: underline;
			// 	}
			// }

			& + li {
				margin-top: 20px;
			}
		}
	}

	section {
		max-height: 0;
		transition: all 0.5s ease-out;
		padding: 0 10px;

		.open {
			margin-bottom: 15px;
		}

		.open + & {
			max-height: 2000px;
			padding: 10px;
		}

		* {
			display: block;
		}

		img + img {
			margin-top: 10px;
		}

		span {
			font-style: italic;

			& + img {
				margin-top: 15px;
			}
		}

		img + span {
			margin-top: 20px;
		}
	}
</style>

<script>
	export default {
		methods: {
			toggle (event) {
				event.target.classList.toggle('open');
			}
		}
	};
</script>

<template>
	<section>
		<h1>Zubehör</h1>
		<img src="@/../public/img/zubehoer/zubehoer.jpg" alt="zubehör" />
		<ul @click="toggle">
			<li>
				<h2>Kerzenleuchter</h2>
				<section>
					<span>Preiswerte Kerzenleuchter nach alten Formen aus Messing-Guß gefertigt</span>
					<img src="@/../public/img/zubehoer/leuchter/leuchter_guenstig1.jpg" alt="leuchter_guenstig1" />
					<img src="@/../public/img/zubehoer/leuchter/leuchter_guenstig2.jpg" alt="leuchter_guenstig2" />

					<span>Handwerklich hochwertige Kerzenleuchter aus massiv Messing und handpoliert</span>
					<img src="@/../public/img/zubehoer/leuchter/leuchter_massiv1.jpg" alt="leuchter_massiv1" />
					<img src="@/../public/img/zubehoer/leuchter/leuchter_massiv2.jpg" alt="leuchter_massiv2" />

					<span>Unsere edelsten Stücke, in Einzelfertigung handgearbeitet</span>
					<img src="@/../public/img/zubehoer/leuchter/leuchter_modern1.jpg" alt="leuchter_modern1" />
					<img src="@/../public/img/zubehoer/leuchter/leuchter_modern2.jpg" alt="leuchter_modern2" />
				</section>
			</li>
			<li>
				<h2>Klavierleuchten</h2>
				<section>
					<img src="@/../public/img/zubehoer/leuchten/klavierleuchten_floete.jpg" alt="klavierleuchten_floete" />
					<img src="@/../public/img/zubehoer/leuchten/klavierleuchten_marmoriert.jpg" alt="klavierleuchten_marmoriert" />
					<img src="@/../public/img/zubehoer/leuchten/klavierleuchten_standard.jpg" alt="klavierleuchten_standard" />
				</section>
			</li>
			<li>
				<h2>Klavierstühle</h2>
				<section>
					<img src="@/../public/img/zubehoer/stuehle/hocker_balz1.jpg" alt="hocker_balz1" />
					<img src="@/../public/img/zubehoer/stuehle/hocker_balz2.jpg" alt="hocker_balz2" />
				</section>
			</li>
		</ul>
	</section>
</template>
